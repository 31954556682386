<template>
  <div>
    <b-row>
      <b-col class="p-0" cols="12">
        <b-card>
          <b-row>
            <b-col cols="12" v-for="(item, index) in auxPrices" :key="index">
              <tabler-icon
                icon="CurrencyDollarIcon"
                size="18"
                class="text-success"
              />
              <span style="font-weight: bold">
                {{ item.title.toUpperCase() }}
              </span>
              <b-input-group class="mt-1 input-money mb-1">
                <money
                  v-model="item.price"
                  v-bind="vMoney"
                  class="form-control"
                  :disabled="!item.editing"
                />
                <b-input-group-append>
                  <template #prepend>
                    <div class="input-group-text"></div>
                  </template>
                  <b-button
                    v-if="!item.editing"
                    variant="outline-primary"
                    class="btn-icon"
                    v-b-tooltip.hover.top="'Tracking'"
                    @click="trackingModal(item)"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                  <b-button
                    v-if="!item.editing"
                    variant="outline-warning"
                    class="btn-icon"
                    :class="item.item === 'price_0' && 'cursor-not-allowed'"
                    v-b-tooltip.hover.top="'Edit'"
                    @click="editPrice(item)"
                    :disabled="!hasPermission || item.item == 'price_0'"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <b-button
                    v-if="item.editing"
                    variant="outline-success"
                    class="btn-icon"
                    v-b-tooltip.hover.top="'Save'"
                    @click="changedPrices(item)"
                  >
                    <feather-icon icon="SaveIcon" />
                  </b-button>
                  <b-button
                    v-if="item.editing"
                    variant="outline-danger"
                    class="btn-icon"
                    v-b-tooltip.hover.top="'Cancel'"
                    @click="disableInput(item)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <TrackingModalPrice
      v-if="showTrackingModal"
      @close="closeTrackingModal"
      :typePrice="idPrice"
      :rate="rate"
    >
    </TrackingModalPrice>
  </div>
</template>

<script>
// SERVICES
import NcrService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";

import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
// COMPONENTS
import TrackingModalPrice from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/TrackingModalPrices.vue";

// STORE
import { mapGetters } from "vuex";

export default {
  name: "PricesNcrType",
  components: {
    TrackingModalPrice,
  },
  data() {
    return {
      idPrice: 0,
      prices: [],
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      auxPrices: [],
      rate: {},
      showTrackingModal: false,
      initialPrices: [],
    };
  },

  props: {
    title: String,
  },
  computed: {
    ...mapGetters({
      theme: "appConfig/skin",
      currentUser: "auth/currentUser",
    }),

    hasPermission() {
      return this.isCeo || this.isChief || this.isSupervisor;
    },
  },
  mounted() {
    this.getPrices();
  },
  methods: {

    editPrice(item) {
      item.editing = true;
    },

    async disableInput(item) {
      item.editing = false;
      await this.getPrices(false);
    },

    async getPrices(reload = true) {
      //if (reload) this.$emit("setBusy", true);

      const dataEdit = {
        editing: false,
      };

      const { data } = await NcrService.getNcrPrices({
        type_ncr: this.title === "AUTOMATIC" ? 1 : 2,
      });
      this.idPrice = data[0].id;
      this.initialPrices = data[0];
      this.prices = [];
      this.prices.push({ ...data[0], ...dataEdit });

      this.newArrayPrices();

      //if (reload) this.$emit("setBusy", false);
    },

    async changedPrices(item) {
      if (
        (item.item !== "price_1" && item.price >= 0) ||
        (item.item === "price_1" && item.price > 0)
      ) {
        const oldPrice = this.initialPrices[item.item];
        if (oldPrice == item.price) {
          this.disableInput(item);
          return;
        }
        let formdata = {
          //id: this.title === "AUTOMATIC" ? 1 : 2,
          id: this.idPrice,
          type_price: item.item,
          price: item.price,
          userId: this.currentUser.user_id,
        };
        const confirm = await this.showConfirmSwal(
          `Are you sure to change the price?`,
          null
        );
        if (confirm.isConfirmed) {
          try {
            let { data } = await SettingsServiceSp.updateNcrPrices(formdata);
            if (data.success) {
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else {
              this.showToast(
                "danger",
                "top-right",
                "Failed!",
                "XIcon",
                "Failed operation"
              );
            }
          } catch (error) {
            this.showErrorSwal(error);
          }
        }
        this.disableInput(item);
      } else {
        this.showToast(
          "danger",
          "top-right",
          "Failed!",
          "XIcon",
          "Invalid number"
        );
      }
    },

    trackingModal(item) {
      this.itemId = this.title === "AUTOMATIC" ? 1 : 2;
      this.rate = item;
      this.showTrackingModal = true;
    },

    closeTrackingModal() {
      this.showTrackingModal = false;
    },

    newArrayPrices() {
      const titlesPrice = {
        price_0: "RATE 0",
        price_1: "RATE 1",
        other_min_price: "OTHER RATE",
      };
      const transformedList = this.prices.reduce((acc, item) => {
        Object.keys(item).forEach((key, index) => {
          if (key !== "editing" && key !== "id") {
            acc.push({
              editing: item["editing"],
              title: titlesPrice[key],
              price: item[key],
              item: key,
              idx: index,
            });
          }
        });
        return acc;
      }, []);
      this.auxPrices = transformedList;
    },
  },
};
</script>
