<template>
  <div class="mt-1">
    <b-card>
      <b-row class="d-flex align-items-center">
        <span class="mr-2"> SETTINGS: </span>
        <div>
          <feather-icon
            icon="SquareIcon"
            style="margin-right: 0.5rem; fill: #ff9f43"
            class="text-warning"
          />
          MANUAL
        </div>

        <div class="ml-2">
          <feather-icon
            icon="SquareIcon"
            style="margin-right: 0.5rem; fill: #8f5fe8"
            class="text-info"
          />
          AUTOMATIC
        </div>
        <div class="mr-2  ml-2">
          <feather-icon
            class="cursor-pointer"
            :class="isBusy ? 'lds-ring' : ''"
            icon="RefreshCcwIcon"
            size="18"
            @click="refreshPrices()"
          />
        </div>
      </b-row>
      <div >
        <div v-for="(title, index) in titles" :key="index">
          <PricesNcrType
            :title="title"
            ref="pricesNcrComponent"
            @setBusy="setBusy"
            :busy="isBusy"
          ></PricesNcrType>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import PricesNcrType from "@/views/specialists/sub-modules/financial-approval/views/settings/views/components/PricesNcrType.vue"
export default {
  components: {
    PricesNcrType,
  },

  data() {
    return {
      total: true,
      transaction: "PAYMENT",
      isBusy: false,
      titles: ["MANUAL", "AUTOMATIC"],
    }
  },
  methods: {
    refreshPrices() {
      const components = this.$refs["pricesNcrComponent"]
      components.forEach(async (component) => {
        await component.getPrices()
      })

      this.total = true
    },

    setBusy(value) {
      this.isBusy = value
    },

    select(type) {
      this.transaction = type === 1 ? "PAYMENT" : "EXPENSES"
      this.total = false
    },
  },
}
</script>

<style lang="scss" scoped>
.lds-ring {
  animation: lds-ring 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.item-purple {
  background-color: #8f5fe8 !important;
  border-color: #8f5fe8 !important;
}

.item-magenta {
  background-color: #ff9f43 !important;
  border-color: #ff9f43 !important;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
