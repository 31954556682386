<template>
  <div>
    <b-modal
      v-model="showModal"
      size="lg"
      :title="titleModal()"
      @hide="closeModal"
      hide-footer
    >
      <b-table
        ref="trackingTable"
        class="blue-scrollbar"
        :items="myProvider"
        :fields="myFields"
        :busy="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
// SERVICES
import SerivcesNcrPrices from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js"

// DATA
import TrackingFields from "@/views/specialists/sub-modules/financial-approval/views/settings/data/trackingPrice.js"

export default {
  props: {
    typePrice: Number,
    rate: Object,
  },

  data() {
    return {
      showModal: false,
      isBusy: false,
      TrackingFields,
      pricesData: [],
    }
  },
  computed: {
    myFields() {
      return this.TrackingFields.filter((field) => field.visible)
    },
  },
  created() {
    this.openModal()
  },
  methods: {
    titleModal() {
      let modalTitle =
        this.typePrice === 1
          ? `AUTOMATIC ${this.rate.title} TRACKING`
          : `MANUAL ${this.rate.title} TRACKING`
      return modalTitle
    },
    openModal() {
      this.showModal = true
    },
    closeModal() {
      this.$emit("close")
    },
    async myProvider() {
      const formData = {
        id: this.typePrice,
        item: this.rate.idx,
      }
      try {
        const { data } = await SerivcesNcrPrices.getTrackingNcrPrices(formData)
        this.pricesData = data.data
        return this.pricesData
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
