import moment from "moment";
export default [
  {
    key: "user",
    label: "User",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: true,
  },

  {
    key: "created_at",
    label: "CREATED AT",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: true,
    formatter: (value) => {
      if (value) return moment(value).format("MM/DD/YYYY hh:mm A");
      return "-";
    },
  },

  {
    key: "old_value",
    label: "OLD PRICE",
    tdClass: "pt-1 px-0 text-center",
    formatter: (value) => `$ ${value}`,
    thClass: "text-center",
    visible: true,
  },

  {
    key: "new_value",
    label: "NEW PRICE",
    tdClass: "pt-1 px-0 text-center",
    formatter: (value) => `$ ${value}`,
    thClass: "text-center",
    visible: true,
  },
];
